import React from "react";
import "./SpotListItem.css";

const SpotListItem = (props) => {
    return props.spots.map((item) =>
        item.online ? (
            <div key={item.location.name}>
                <div className="SpotListItem-wrapper-online animate__animated animate__slideInDown">
                    <div className="row">
                        <div className="col-xs-2 SpotListItem-dot-online" />
                        <div className="col">
                            <p className="SpotListItem-name SpotListItem-left">
                                {item.location.name}
                            </p>
                            <p className="SpotListItem-song SpotListItem-left">
                                <i className="fab fa-itunes-note"></i>{" "}
                                {item.onAir.length > 24
                                    ? item.onAir.substr(0, 24) + "..."
                                    : item.onAir}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div key={item.location.name}>
                <div className="SpotListItem-wrapper-offline animate__animated animate__slideInDown">
                    <div className="row">
                        <div className="col-xs-2 SpotListItem-dot-offline" />
                        <div className="col">
                            <p className="SpotListItem-name SpotListItem-left">
                                {item.location.name}
                            </p>
                            <p className="SpotListItem-song SpotListItem-left">
                                <i className="fab fa-itunes-note"></i>{" "}
                                {item.onAir.length > 24
                                    ? item.onAir.substr(0, 24) + "..."
                                    : item.onAir}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default SpotListItem;

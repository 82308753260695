import axios from "axios";

export const GET_spotList = () =>
    new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: `https://api.moramma.com/openapi/location`,

            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((data) => {
                console.log("ok");
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });

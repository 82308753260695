import React, { useState, useEffect } from "react";

import NavBar from "../NavBar/NavBar";
import "./SpotList.css";
import SpotListItem from "./SpotListItem/SpotListItem";
import { GET_spotList } from "./../../utils/API";
import MyLoader from "./MyLoader";

function SpotList() {
    const [morammaSpotList, setMorammaSpotList] = useState({});

    useEffect(() => {
        async function fetchData() {
            const res = await GET_spotList();
            setMorammaSpotList(res.data.data);
            console.log(res.data.data);
        }

        let timer1 = setTimeout(() => fetchData(), 1000);
        return () => {
            clearTimeout(timer1);
        };
    }, []);

    return (
        <>
            <NavBar />
            <div className="container">
                <br />
                <div className="row">
                    <div className="col SpotList-txtCenter">
                        <p className="SpotList-title">
                            Discover Moramma™ Spots
                        </p>
                        <p className="SpotList-info">
                            <i class="fas fa-info-circle"></i> The blues are
                            online, the reds are offline
                        </p>
                        <br />
                        {morammaSpotList.length > 1 ? (
                            <SpotListItem spots={morammaSpotList} />
                        ) : (
                            <MyLoader />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default SpotList;

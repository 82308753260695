import React from "react";

import "./NavBar.css";

function NavBar() {
    return (
        <>
            <div className="container">
                <nav class="navbar navbar-expand-lg navbar-light bg-light NavBar-style">
                    <p className="NavBar-Logo">
                        <a href="https://moramma.com">Moramma.com</a>
                    </p>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarNavAltMarkup"
                        aria-controls="navbarNavAltMarkup"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        class="collapse navbar-collapse"
                        id="navbarNavAltMarkup"
                    >
                        <div className="navbar-nav ml-auto NavBar-align">
                            <div className="NavBar-demo">
                                <p>
                                    <a
                                        target="blank"
                                        href="mailto:moramma.music@gmail.com"
                                    >
                                        Request a Demo
                                    </a>
                                </p>
                            </div>
                            <p className="NavBar-item-container">
                                <a
                                    target="blank"
                                    href="https://moramma.com/tut-it.html"
                                >
                                    <i className="fas fa-question-circle NavBar-item"></i>
                                </a>
                            </p>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
}

export default NavBar;

import React from "react";
import { Route } from "react-router-dom";

import Home from "../Home/Home";
import SpotList from "../SpotList/SpotList";

function Layout() {
    return (
        <>
            <Route path="/" exact component={Home} />
            <Route path="/spot-list" exact component={SpotList} />
        </>
    );
}

export default Layout;

import React, { useState } from "react";

import NavBar from "./../NavBar/NavBar";

import "./Home.css";
import IcMainIllustration from "./../../statics/ic/illustration.png";
import IcApp from "./../../statics/ic/moramma_tel.png";
import IcPlayerDashboard from "./../../statics/ic/dashboard.png";
import IcPlayerHome from "./../../statics/ic/home.png";
import IcPlayerSettings from "./../../statics/ic/settings.png";

function Home() {
    const [selectedPlayerImage, setselectedPlayerImage] = useState(0);

    return (
        <>
            <NavBar />
            <div className="container">
                <div className="row Home-intro-container">
                    <div className="col-md-6">
                        <p className="Home-beta-title animate__animated animate__slideInDown">
                            We are on beta release.
                        </p>
                        <h1 className="Home-main-title animate__animated animate__slideInDown">
                            Moramma™: an Ambient Setting Platform
                        </h1>
                        <p className="Home-main-subtitle animate__animated animate__slideInDown">
                            Moramma™ manages the music of the environment
                            according to the taste of the users.
                        </p>
                        <br />
                        <p>
                            <span className="Home-websection-demo">
                                <a href="/spot-list">View Moramma™ Spots</a>
                            </span>
                        </p>
                    </div>
                    <div className="col">
                        <img
                            className="Home-illustration animate__animated animate__fadeIn img-responsive"
                            src={IcMainIllustration}
                            alt="home illustration"
                        />
                    </div>
                </div>
            </div>
            <div className="Home-appsection-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <img
                                src={IcApp}
                                alt="app"
                                className="img-fluid wow animate__animated animate__fadeIn Home-appsection-appimg"
                            />
                        </div>
                        <div className="col Home-Mt wow animate__animated animate__fadeIn">
                            <p className="Home-appsection-title">
                                You are in control.
                            </p>
                            <p className="Home-appsection-subtitle">
                                When you are inside a Moramma™ Spot the
                                environment will adapt automatically (or
                                manually) to your taste.
                            </p>
                            <br />
                            <p className="Home-appsection-item">
                                <i className="fas fa-info-circle"></i> Use
                                Moramma™ Spots on the map to find out in
                                real-time what's happening in your favorite
                                clubs, restaurants, and gyms.
                            </p>

                            <p className="Home-appsection-item">
                                <i className="fas fa-music"> </i> Create, edit
                                and manage the playlists to be proposed in the
                                spot.
                            </p>

                            <p className="Home-appsection-item">
                                <i className="fas fa-calendar-day"> </i> Join
                                and participate in events with friends in your
                                favorite spots
                            </p>
                            <p className="Home-appsection-item">
                                <i className="fas fa-share-alt-square"> </i>{" "}
                                Interact with the spot, adding your musical
                                preferences.
                            </p>
                            <p className="Home-appsection-item">
                                <i className="fas fa-users"> </i>
                                Find new friends to share your best moments
                                with.
                            </p>
                            <br />
                            <p>
                                <span className="Home-appsection-download">
                                    <a
                                        href="https://apps.apple.com/it/app/moramma/id1382717294"
                                        target="blank"
                                    >
                                        <i class="fab fa-app-store-ios"></i>{" "}
                                        Download for iOS
                                    </a>
                                </span>
                            </p>
                            <br />
                            <p>
                                <span className="Home-appsection-download">
                                    <a
                                        href="https://play.google.com/store/apps/details?id=wagooitalia.wagoomusic&hl=it"
                                        target="blank"
                                    >
                                        <i class="fab fa-google-play"></i>{" "}
                                        Download for Android
                                    </a>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col Home-txtCenter Home-websection-spacing">
                        <h1 className="Home-main-title animate__animated animate__slideInUp">
                            Built for you.
                        </h1>
                        <p className="Home-websection-subtitle">
                            Define your environment settings, attract users and
                            let them interact with the system.
                        </p>
                        <p className="Home-websection-descr">
                            With the Moramma™ Spot account manager you can have
                            the last word on everything: check playlists,
                            volume, bans and much more!
                        </p>
                        <br />
                        <div>
                            <p>
                                <span className="Home-websection-demo">
                                    <a
                                        target="blank"
                                        href="https://player.moramma.com"
                                    >
                                        Become a Moramma™ Spot
                                    </a>
                                </span>
                            </p>
                        </div>
                        <br />
                        <br />
                        {selectedPlayerImage === 0 ? (
                            <>
                                <p className="Home-websection-menuplayer-selected">
                                    Home
                                </p>
                                <p
                                    className="Home-websection-menuplayer"
                                    onClick={() => setselectedPlayerImage(1)}
                                >
                                    Dashboard
                                </p>
                                <p
                                    className="Home-websection-menuplayer"
                                    onClick={() => setselectedPlayerImage(2)}
                                >
                                    Settings
                                </p>
                                <br />
                                <br />
                                <img
                                    src={IcPlayerHome}
                                    alt="home"
                                    className="img-fluid"
                                />
                            </>
                        ) : (
                            ""
                        )}
                        {selectedPlayerImage === 1 ? (
                            <>
                                <p
                                    className="Home-websection-menuplayer"
                                    onClick={() => setselectedPlayerImage(0)}
                                >
                                    Home
                                </p>
                                <p className="Home-websection-menuplayer-selected">
                                    Dashboard
                                </p>
                                <p
                                    className="Home-websection-menuplayer"
                                    onClick={() => setselectedPlayerImage(2)}
                                >
                                    Settings
                                </p>
                                <br />
                                <br />
                                <img
                                    src={IcPlayerDashboard}
                                    alt="dashboard"
                                    className="img-fluid"
                                />
                            </>
                        ) : (
                            ""
                        )}
                        {selectedPlayerImage === 2 ? (
                            <>
                                <p
                                    className="Home-websection-menuplayer"
                                    onClick={() => setselectedPlayerImage(0)}
                                >
                                    Home
                                </p>
                                <p
                                    className="Home-websection-menuplayer"
                                    onClick={() => setselectedPlayerImage(1)}
                                >
                                    Dashboard
                                </p>
                                <p className="Home-websection-menuplayer-selected">
                                    Settings
                                </p>
                                <br />
                                <br />
                                <img
                                    src={IcPlayerSettings}
                                    alt="settings"
                                    className="img-fluid"
                                />
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
            <div className="Home-playersection-bg">
                <div className="container">
                    <br />
                    <br />
                    <div className="row">
                        <div className="col Home-txtCenter">
                            <p className="Home-main-title">
                                Increase your clients!
                            </p>
                            <br />
                            <p className="Home-websection-subtitle">
                                Use Moramma™ analytics tools to know what your
                                customers like, and create unique events!
                                Becoming a Moramma™ spot you will be visible to
                                all users who use Moramma™ to find their
                                favorite spot to interact with music, socialize
                                but not only!
                            </p>
                            <br />
                            <p>
                                <span className="Home-websection-demo">
                                    <a
                                        target="blank"
                                        href="https://player.moramma.com"
                                    >
                                        Become a Moramma™ Spot
                                    </a>
                                </span>
                            </p>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="Home-teamsection-bg">
                <div className="container">
                    <div className="row">
                        <div className="col Home-txtCenter">
                            <br />
                            <br />
                            <p className="Home-appsection-title">
                                Meet the team
                            </p>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col Home-txtCenter Home-teamsection-item">
                            <img src={IcTeam0} alt="team" />
                            <p>Federico Fraccaroli</p>
                            <p>Founder/Vision</p>
                        </div>
                        <div className="col Home-txtCenter Home-teamsection-item">
                            <img src={IcTeam1} alt="team" />
                            <p>Gianluca Benedetti</p>
                            <p>CEO</p>
                            <p>
                                <i>
                                    International Business Development,
                                    Executive MBA, BSBA Marketing
                                </i>
                            </p>
                        </div>
                        <div className="col Home-txtCenter Home-teamsection-item">
                            <img src={IcTeam2} alt="team" />
                            <p>Florenc Demrozi</p>
                            <p>Ph.D</p>
                            <p>
                                <i>
                                    Ambient Intelligence, Ambient Assisted
                                    Living and Embedded Systems
                                </i>
                            </p>
                        </div>
                        <div className="col Home-txtCenter Home-teamsection-item">
                            <img src={IcTeam3} alt="team" />
                            <p>Carlo Tacchella</p>
                            <p>M.S. in CS and Engineering</p>
                            <p>
                                <i>
                                    Full-stack developer web and multi-platform
                                    mobile
                                </i>
                            </p>
                        </div>
                        <div className="col Home-txtCenter Home-teamsection-item">
                            <img src={IcTeam4} alt="team" />
                            <p>Fabio Chiarani</p>
                            <p>M.S. CS and Engineering Student</p>
                            <p>
                                <i>Developer</i>
                            </p>
                        </div>
                        <div className="col Home-txtCenter Home-teamsection-item">
                            <img src={IcTeam5} alt="team" />
                            <p>Francesco Tonini</p>
                            <p>M.S. CS and Engineering Student</p>
                            <p>
                                <i>App Developer</i>
                            </p>
                        </div>
                    </div>
                    <br />
                    <br />
                </div>
            </div> */}
            <div className="Home-footer-bg">
                <div className="container">
                    <div className="row">
                        <div className="col Home-txtCenter">
                            <p className="Home-footer-content">
                                WAGOO Italia S.R.L.S | P.IVA / C.F. 04457880237
                                | Built with <i className="fas fa-mug-hot"></i>{" "}
                                and <i className="fas fa-heart"></i> in Verona.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;
